
    .carousel.carousel-slider li.slide.selected {
        z-index: 0 !important;
    }
    .carousel .control-next.control-arrow:before { 
        border: none;
        margin-right: 3rem;
        content: url('./assets/svg/next.svg')
    }
    .carousel .control-prev.control-arrow::before { 
        border: none;
        margin-left: 3rem;
        content: url('./assets/svg/prev.svg')
    }
    .carousel.carousel-slider .control-arrow {
        opacity: 1;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: transparent;
    }
    
@media screen and (max-width: 900px) {
    .carousel .control-prev.control-arrow:before, .carousel .control-next.control-arrow:before  {
        display: none;
    }  
    .carousel.carousel-slider .control-arrow {
        display: none;
    }
    
}