.rec-dot_active {
    /* background-color: var(--blue) !important; */
    background-color: #ccc!important;
    box-shadow: 0 0 1px 3px var(--blue) !important;
}
.rec-dot:focus,
.rec-dot:hover {
    box-shadow: 0 0 1px 3px var(--blue) !important;
}
.rec-dot_active:focus,
.rec-dot_active:hover {
    background-color: #ccc!important;
    /* background-color: var(--blue) !important; */
    box-shadow: 0 0 1px 3px var(--blue) !important;
}
.rec-arrow {  
    background-color: transparent!important;
    transition: all 0.3s ease;
    font-size: 1.1rem !important;
    color: #ccc !important;
    width: 1.7rem !important;
    height: 1.7rem !important;
    min-width: 1.7rem !important;
    line-height: 1.7rem !important;
}
@media screen and (max-width: 330px){
    .rec-arrow {  
        background-color: transparent!important;
        transition: all 0.3s ease;
        font-size: .9rem !important;
        color: #ccc !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
        min-width: 1.5rem !important;
        line-height: 1.5rem !important;
    }  
}

.rec-arrow:hover:not(:disabled) {
    transform: scale(1.2);
}